var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex justify-content-between align-items-center pt-50 mb-2"},[_c('h2',{staticClass:"font-weight-bolder m-0"},[_c('span',{on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('b-button',{staticClass:"btn-icon rounded-circle mr-50 text-danger bg-white",attrs:{"variant":"white","size":"sm"}},[_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon"}})],1)],1),_vm._v(" Calendario laboral ")])]),_c('b-card',[_c('validation-observer',{ref:"editCalendar"},[_c('b-form',{staticClass:"mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.handleSubmit.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":'Nombre',"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('p',[_c('strong',[_vm._v("¿Qué tipo de jornada quieres?")])]),_c('b-form-radio',{staticClass:"d-inline",attrs:{"name":"some-radios","value":"continua"},model:{value:(_vm.typeJourney),callback:function ($$v) {_vm.typeJourney=$$v},expression:"typeJourney"}},[_vm._v(" Jornada continua ")]),_c('b-form-radio',{staticClass:"d-inline ml-4",attrs:{"name":"some-radios","value":"partida"},model:{value:(_vm.typeJourney),callback:function ($$v) {_vm.typeJourney=$$v},expression:"typeJourney"}},[_vm._v(" Jornada partida ")])],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"cols":"12 mt-2"}},[_c('p',[_c('strong',[_vm._v("Define el horario de la jornada y los descansos")])])]),_c('b-col',{attrs:{"sm":"3"}},[_c('validation-provider',{attrs:{"name":'Inicio',"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":'Inicio',"label-for":"calendar-fechaini"}},[_c('b-form-timepicker',{attrs:{"local":"es","no-close-button":true,"hide-header":true},model:{value:(_vm.date_ini),callback:function ($$v) {_vm.date_ini=$$v},expression:"date_ini"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"sm":"3"}},[_c('validation-provider',{attrs:{"name":'Fin',"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":'Fin',"label-for":"calendar-fechafin"}},[_c('b-form-timepicker',{attrs:{"local":"es","no-close-button":true,"hide-header":true},model:{value:(_vm.date_fin),callback:function ($$v) {_vm.date_fin=$$v},expression:"date_fin"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"sm":"3"}},[_c('validation-provider',{attrs:{"name":'Descanso almuerzo',"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":'Descanso almuerzo',"label-for":"calendar-fechaalmuerzo"}},[_c('b-form-timepicker',{attrs:{"local":"es","no-close-button":true,"hide-header":true},model:{value:(_vm.date_almuerzo),callback:function ($$v) {_vm.date_almuerzo=$$v},expression:"date_almuerzo"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"sm":"3"}},[_c('validation-provider',{attrs:{"name":'Descanso comida',"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":'Descanso comida',"label-for":"calendar-fechacomida"}},[_c('b-form-timepicker',{attrs:{"local":"es","no-close-button":true,"hide-header":true},model:{value:(_vm.date_comida),callback:function ($$v) {_vm.date_comida=$$v},expression:"date_comida"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"cols":"12 text-right"}},[_c('b-button',{staticClass:"mt-2 mr-1",attrs:{"type":"submit","variant":"primary"}},[_vm._v(" "+_vm._s('Enviar')+" ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }