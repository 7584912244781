<template>
  <div>
    <div class="d-flex justify-content-between align-items-center pt-50 mb-2">
      <h2 class="font-weight-bolder m-0" >
        <span @click="$router.go(-1)" >
          <b-button
              variant="white"
              class="btn-icon rounded-circle mr-50 text-danger bg-white"
              size="sm"
          >
            <feather-icon icon="ChevronLeftIcon" />
          </b-button>
        </span> Calendario laboral
      </h2>
    </div>
    <b-card>
      <!-- form -->
      <validation-observer ref="editCalendar">
        <b-form
          class="mt-2"
          @submit.prevent="handleSubmit"
          @keydown.enter="handleSubmit"
        >
          <b-row>
            <b-col cols="12" >
              <validation-provider
                #default="{ errors }"
                :name="'Nombre'"
                rules="required"
              >
                <b-form-group
                >
                  <p><strong>¿Qué tipo de jornada quieres?</strong></p>
                  <b-form-radio
                    v-model="typeJourney"
                    name="some-radios"
                    value="continua"
                    class="d-inline"
                  >
                    Jornada continua
                  </b-form-radio>
                  <b-form-radio
                    v-model="typeJourney"
                    name="some-radios"
                    value="partida"
                    class="d-inline ml-4"
                  >
                    Jornada partida
                  </b-form-radio>
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col cols="12 mt-2" >
              <p><strong>Define el horario de la jornada y los descansos</strong></p>
            </b-col>
            <b-col sm="3">
              <validation-provider
                #default="{ errors }"
                :name="'Inicio'"
                rules="required"
              >
                <b-form-group
                  :label="'Inicio'"
                  label-for="calendar-fechaini"
                >
                  <b-form-timepicker
                    v-model="date_ini"
                    local="es"
                    :no-close-button="true"
                    :hide-header="true"
                  />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col sm="3">
              <validation-provider
                #default="{ errors }"
                :name="'Fin'"
                rules="required"
              >
                <b-form-group
                  :label="'Fin'"
                  label-for="calendar-fechafin"
                >
                  <b-form-timepicker
                    v-model="date_fin"
                    local="es"
                    :no-close-button="true"
                    :hide-header="true"
                  />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col sm="3">
              <validation-provider
                #default="{ errors }"
                :name="'Descanso almuerzo'"
                rules="required"
              >
                <b-form-group
                  :label="'Descanso almuerzo'"
                  label-for="calendar-fechaalmuerzo"
                >
                  <b-form-timepicker
                    v-model="date_almuerzo"
                    local="es"
                    :no-close-button="true"
                    :hide-header="true"
                  />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col sm="3">
              <validation-provider
                #default="{ errors }"
                :name="'Descanso comida'"
                rules="required"
              >
                <b-form-group
                  :label="'Descanso comida'"
                  label-for="calendar-fechacomida"
                >
                  <b-form-timepicker
                    v-model="date_comida"
                    local="es"
                    :no-close-button="true"
                    :hide-header="true"
                  />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col cols="12 text-right">
              <b-button
                type="submit"
                variant="primary"
                class="mt-2 mr-1"
              >
                {{ 'Enviar' }}
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  BButton, BForm, BFormGroup, BRow, BCol, BCard, BFormRadio, BFormTimepicker,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import Ripple from 'vue-ripple-directive'
import { FlatPickrToTimestamp, TimestampToFlatPickr } from '@/libs/helpers'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BRow,
    BCol,
    BCard,
    ValidationProvider,
    ValidationObserver,
    BFormRadio,
    BFormTimepicker,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      typeJourney: '',
      date_ini: '',
      date_fin: '',
      date_almuerzo: '',
      date_comida: '',
      required,
      email,
    }
  },
  computed: {
    ...mapGetters({
      calendar: 'calendar/getCalendar',
    }),
  },
  methods: {
    ...mapActions({
      edit: 'calendar/edit',
      getCalendar: 'calendar/getCalendar',
    }),
    handleSubmit() {
      this.$refs.editCalendar.validate().then(success => {
        if (success) {
          const formData = this.createFormData()
          if (formData) {
            this.edit({ calendar: formData })
          }
        }
      })
    },
    setData() {
      this.typeJourney = this.calendar.type_journey
      this.date_fin = (this.calendar.hour_fin) ? this.calendar.hour_fin : '00:00:00'
      this.date_ini = (this.calendar.hour_ini) ? this.calendar.hour_ini : '00:00:00'
      this.date_almuerzo = (this.calendar.min_breakfast) ? this.calendar.min_breakfast : '00:00:00'
      this.date_comida = (this.calendar.min_lunch) ? this.calendar.min_lunch : '00:00:00'
    },
    createFormData() {
      const data = new FormData()
      data.append('type_journey', this.typeJourney)
      data.append('min_breakfast', this.date_almuerzo)
      data.append('min_lunch', this.date_comida)
      data.append('hour_fin', this.date_fin)
      data.append('hour_ini', this.date_ini)

      return data
    },
  },
  async created() {
    await this.getCalendar()
    await this.setData()
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
